<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head"><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link> {{ $route.name }}<div class="subHeadTitle">{{ $route.name }}</div>
    <div class="right" style="margin-top: 1.2rem;" v-if="PERMISIONS.create">
      <div @click="addItem" class="Button1">
        <div class="icon">
          <img @load="imgLoaded" src="../assets/media/for_table/circle_ADD_new_etap.svg" alt="">
        </div>
        <span class="Btn_content">
          Adaugă Categorie CPV
        </span>
      </div>
    </div>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        :apiModuleName="'CPV_CATEGORY_PAGINATED'"
        :tableMinimizeHead="true"
        :tableSpread="true"
        @Btn2="editItem" 
        @Btn3="deleteItem"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from '@/mixins/cpvCategoryPermMixin.js'
import { CPV_CATEGORY_PAGINATED } from '../api.js'
import { mapMutations } from 'vuex'
import TableParent from '@/components/TableParent'

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`
  },
  inject: ['cpv'],
  mixins: [PermisionsMixin],
  components: {
    TableParent
  },
  data() {
    return {
      tableHead: [
        {title:'Denumire diviziune',sort:true, 
        queryKey: 'division',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Denumire Grup',sort:true, 
        queryKey: 'group',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Denumire Clasă',sort:true, 
        queryKey: 'class',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Nume categorie',sort:true, 
        queryKey: 'category',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Cod CPV',sort:true,
        queryKey: 'cpvCode',
        bottomHead: {
            quikFiltre: true
        }}
      ],
      tableModule: CPV_CATEGORY_PAGINATED,
      totalRecords: 0,
      reloadTable: 0
    }
  },
  watch: {
    cpv: {
      handler() {
        this.reload()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['addWindow']),
    reload() {
      this.reloadTable++
    },
    prepareData(row) {
      row = row || {}

      const preparation = [row.division || '-',row.group || '-',row.class || '-',row.name,row.cpvCode]

      const uuid = row.createdByUser
      const x = this.PERMISIONS
      const prepareBtn = []

      if(this.checkOwner(x.edit, uuid)) {
        prepareBtn.push(2)
      }
      if(this.checkOwner(x.delete, uuid)) {
        prepareBtn.push(3)
      }

      if(prepareBtn.length) {
        preparation.push([prepareBtn])
      }
      
      return preparation
    },
    deleteItem(item) {
      if(!Number.isInteger(item?.id)) {
        this.$toastr.e('Anunțați administratorul.')
        return
      }
      this.addWindow({
        title: 'Șterge categorie cpv',
        cpvCategoryId: item.id,
        cpvCategoryData: {},
        modal: 8.2
      })
    },
    editItem(item) {
      if(!Number.isInteger(item?.id)) {
        this.$toastr.e('Anunțați administratorul.')
        return
      }

      this.addWindow({
        title: 'Modifică categorie cpv',
        cpvCategoryId: item.id,
        modal: 8.1
      })
    },
    addItem() {
      this.addWindow({
        title: 'Adaugă categorie cpv',
        modal: 8
      })
    },
  },
  created() {
    if(!this.PERMISIONS.list) {
      return
    }
    
    const canViewButtonsColumn = ['edit','delete']

    if(this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({spacer:true,minimize:1,fixed:{
        right: true
      },bottomHead: {
          quikFiltre: 'clear'
      }})
    }
  }
}
</script>